<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 487.3 487.53"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M485.19,210.15C471.52,102.4,372.18-1.92,242.77,0,117,1.92,34.86,92.12,12.45,168.81c16.71,0,33.37.06,50-.12a5.29,5.29,0,0,0,4-2.54,182.19,182.19,0,0,1,25.42-42.82c18.7-23.09,40.86-41.87,67.9-54.66a183,183,0,0,1,57.33-16.43c30-3.54,59.51-1.48,88.38,7.76s53.39,25.33,74.74,46.58c14.27,14.2,26.51,30,35.68,47.89,18.78,36.77,23.89,75.87,19,116.52a180.36,180.36,0,0,1-25.48,73.49C398.22,362.72,384,378.32,368,392.3a175.25,175.25,0,0,1-68.86,37.28,207.82,207.82,0,0,1-87.13,5.11c-29.86-4.41-57.13-15.19-81.48-33.17C101.39,380,79.34,352.76,65.39,319H12C46.73,423.59,154.33,506.44,288,483.77,398.9,465,503.56,355,485.19,210.15Z"/><path d="M171.21,320.59l34.24,34.3L316.53,243.82,204.34,131.63c-10.7,11.63-21.87,23.75-33.68,36.57l51.08,51H0v49.1H222.32Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconLogIn',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
